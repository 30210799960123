import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../auth.service';
import { Notify } from 'src/app/shared/services/notification.service';

@Injectable()
export class AuthGuard {
  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private notify: Notify) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const _isValid = this.authService.isLoggedIn();
    if (!_isValid) {
      if (!['/', '/login'].includes(this.router.url)) {
        this.router.navigate(['/login'], {
          queryParams: { redirect_uri: window.location.href },
        });
      } else {
        this.router.navigate(['/login'])
      }
    }

    return true;
  }
}
